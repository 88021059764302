@import 'common/styles/global';

@keyframes price-bounce {
  0% {
    background-color: $color-grey5;
    transform: scale(1);
  }

  35% {
    background-color: $color-grey1;
    transform: scale(1);
  }

  45% {
    background-color: $color-grey5;
    transform: scale(1.2);
  }

  100% {
    background-color: $color-grey5;
    transform: scale(1);
  }
}
@keyframes price-pulse {
  0% {
    background-color: $color-grey5;
    opacity: 0.3;
    transform: scale(1);
  }

  35% {
    background-color: $color-grey1;
    opacity: 0.3;
    transform: scale(1);
  }

  45% {
    background-color: $color-grey1;
    opacity: 0.3;
    transform: scale(3.3);
  }

  100% {
    background-color: $color-grey1;
    opacity: 0;
    transform: scale(13);
  }
}

.bet {
  border-radius: 2px;
  padding: 16px 16px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid var(--color-Neutral-3);
  background-color: var(--color-Neutral-1);
  &__tooltip {
    display: grid;
    gap: 24px;
    width: 240px;
    &--row {
      display: flex;
      justify-content: space-between;
      color: var(--color-Neutral-4);
      font-size: 12px;
      span {
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    box-sizing: content-box;
    &__left {
      display: flex;
      gap: 12px;
      &--img {
        background-color: var(--color-Neutral-3);
        width: 40px;
        height: 40px;
        border-radius: 8px;
        position: relative;
        display: flex;
        contain: content;
        svg {
          position: absolute;
          right: 2px;
          bottom: 2px;
        }
        img {
          object-fit: cover;
        }
      }
    }
    &__right {
      &.skeleton {
        display: block;
        width: 85px;
        height: 35px;
        border-radius: 8px;
      }
    }
  }

  &__outcome-title {
    @extend %typography-thumb-outcome-name;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }

  &__type {
    font-size: 14px;
    color: var(--color-Neutral-4);
    font-weight: 500;

    &.skeleton {
      display: block;
      width: 40px;
      height: 10px;
      border-radius: 8px;
    }
  }

  &__separator {
    color: $color-shade-3;
    font-style: normal;
    margin: 0 0.2em;
  }

  &__currency-symbol {
    font-size: 29px;
    line-height: 1;
    display: flex;
    margin-right: 0.5rem;
  }
  &__currency-betCount {
    font-size: 13px;
    line-height: 1;
    display: flex;
    color: var(--color-primary-pure);
    background: var(--color-Neutral-1);
    border: 2px solid var(--color-primary-pure);
    border-radius: 100%;
    width: 18px;
    height: 18px;
    display: flex;
    padding: 0px;
    box-shadow: 1px 1px 4px #1a44637a;
    font-style: normal;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: -5px;

    &--pulse-enter {
      &::before {
        position: absolute;
        z-index: -1; // Behind price text
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 32px;
        height: 44px;
        margin: 0 auto;
        border-radius: 50%;
        content: '';
      }
    }

    &--pulse-enter-active {
      animation: price-bounce linear $time-normal;

      &::before {
        animation: price-pulse linear $time-normal;
      }
    }
  }
  &__badge {
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 500px; 
    @extend %label-md;
    &--p {
      background-color: $color-informative-low;
      color: $color-informative-high;
    }
    &--s,
    &--ps  {
      background-color: $color-warning-low;
      color: $color-warning-high;
    }
    &--w {
      background-color: $color-positive-low;
      color: $color-positive-high;
    }
    &--l {
      background-color: $color-negative-low;
      color: $color-negative-high;
    }
    &--x,
    &--r,
    &--o,
    &--d {
      background-color: var(--color-Neutral-3);
      color: var(--color-Neutral-6);
    }
    &--paused {
      background-color: #b9defa;
      color: #3ea4f0;
    }
  }
  &__price {
    color: var(--color-primary-pure);
    text-transform: initial;
    font-weight: 600;
  }

  &__sell-button {
    line-height: 1.25;
  }
  &__pending-button {
    line-height: 1.25;
    cursor: default;
    pointer-events: none;
    color: $color-grey3 !important;
    font: bold;
  }

  &__market-title {
    @extend %typography-thumb-outcome-name;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--color-Neutral-3);
    span {
      color: var(--color-Neutral-4);
      font-weight: 500;

      &.skeleton {
        display: block;
        width: 250px;
        height: 25px;
        border-radius: 8px;
      }
    }
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    flex-direction: column;
    @media screen and (min-width: 640px) {
      flex-direction: row;
    }
    &--col {
      flex-direction: column;
      gap: 8px;

      .place-bet-info-entry {
        &__content {
          align-items: flex-end;
          &--main {
            flex-direction: row-reverse;
          }
        }
        &__container {
          flex-direction: row;
        }
      }
    }
  }
  &--soldBetList {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
  }
  &__grid-col {
    &--button {
      display: flex;
      align-items: center;
    }
    &--percent {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__grid-title {
    display: block;
    color: $color-shade-2;
    font-size: 12px;
  }

  &__info {
    width: 18px;
    height: 18px;
    padding: 0;
    font-size: 18px;
    background-color: transparent !important;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    &__sellFor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      margin-top: 16px;
      &--right {
        display: flex;
        gap: 8px;
        align-items: center;
        &-usd {
          font-size: 12px;
          font-weight: 600;
          color: #6b6a6a;
          margin-top: 4px;
        }
      }
    }
  }
}
