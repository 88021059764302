@import 'common/styles/global';

.check-control {
  width: 18px;
  height: 18px;
  position: relative;
  background-color: transparent;
  border: 2px solid $color-grey2;
  border-radius: 4px;
  transition: background-color 0.2s linear, border-color 0.2s linear;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 9px;
    height: 5px;
    margin-top: 3px;
    margin-left: 3px;
    border: 0;
    border-left: 1px solid var(--color-Neutral-1);
    border-bottom: 1px solid var(--color-Neutral-1);
    transition: background-color 0.1s linear, margin 0.1s linear;
    transform: rotate(-45deg);
  }
  &--disabled {
    border-color: $color-grey2;
    background-color: $color-grey2;

    &::before {
      border: none;
      background-color: $color-grey2;
    }
  }
  &--active {
    background-color: var(--color-primary-pure);
    border-color: var(--color-primary-pure);

    &::before {
      border-color: var(--color-Neutral-1);
    }
  }
}

.radio-control {
  width: 20px;
  height: 20px;
  // margin-left: 5px;
  // margin-top: 2px;
  border: 1px solid var(--color-Neutral-3);
  border-radius: 50%;
  transition: background-color 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 0.2s linear;
    transform: translateZ(1px);
  }

  &--disabled {
    border-color: $color-grey2;
    background-color: $color-grey2;

    &::before {
      border: none;
      background-color: $color-grey2;
    }
  }
  &--active {
    border-color: var(--color-primary-pure);
    background-color: var(--color-primary-pure);

    &::before {
      background-color: var(--color-Neutral-1);
    }
  }
}

.toggle-control {
  cursor: pointer;
  width: 40px;
  height: 24px;
  border: 1px solid var(--color-Neutral-3);
  border-radius: 16px;
  transition: background-color 0.1s linear;
  position: relative;
  &::before {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-top: 3px;
    border-radius: 50%;
    content: '';
    transition: background-color 0.1s linear, margin 0.1s linear;
    background-color: var(--color-Neutral-3);
  }
  &:hover {
    background-color: var(--color-Neutral-3);
    &::before {
      background-color: var(--color-Neutral-2);
    }
  }
  &--disabled {
    border-color: var(--color-Neutral-3);
    background-color: var(--color-Neutral-3);

    &::before {
      background-color: #4c626d;
    }
    &--active {
      border-color: var(--color-primary-pure);
      background-color: var(--color-primary-pure);

      &::before {
        background-color: var(--color-Neutral-1);
      }
    }
  }
  &--loading { 
    &::before {
      display: none;
    }
    &:hover {
      background-color: var(--color-Neutral-3);
      border-color: var(--color-Neutral-3);
      &::before {
        background-color: #083a5f;
      }
    }
  }
  &--active {
    background-color: var(--color-primary-pure);
    border-color: var(--color-primary-pure);
    &::before {
      margin-left: 17px;
      background-color: var(--color-Neutral-1);
    }

    &:hover {
      background-color: var(--color-primary-low);
      border-color: var(--color-primary-low);

      &::before {
        background-color: #083a5f;
      }
    }
  }
}
