@import '../styles/global';

.loading {
  margin: 0 auto;

  &__container {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }

  &--list {
    width: 56px !important;
    fill: $color-grey3 !important;
  }

  &--overlay {
    width: 80px;
    fill: $color-grey3;
  }

  &--inline {
    width: 40px;
    fill: $color-grey3;
  }
}
