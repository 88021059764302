@import 'common/styles/global';

.view-crypto-cash-in {
 
  font-family: 'Lato';
  overflow: visible;
  width: 560px;
  // padding: 30px 32px 32px;
  color: var(--color-Neutral-6);

  .action-sheet__header {
    border-bottom: unset; 
    padding-bottom: unset;
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: unset;
    height: 100%;
    position: fixed;
    z-index: 50;
    background: var(--color-Neutral-1);
    overflow-y: auto;
    width: 100%;
    top: 0;
    left: 0;

    &__fees {
      position: fixed !important;
    }
    ul {
      max-height: calc(100vh - 130px) !important;
      overflow: auto !important;
      padding-bottom: 4em !important;
    }

    .action-sheet__header {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      background: var(--color-Neutral-1);
      z-index: 2;
      padding: 16px;
    }
  }
  .ui.selection.dropdown {
    background: transparent;
    color: var(--color-Neutral-6);
    border: 0.1em solid var(--color-Neutral-3);
    &:hover {
      background: var(--color-Neutral-3) !important;
      cursor: text;
    }
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    color: var(--color-Neutral-6);
  }
  &--missing-info {
    overflow: visible !important;
  }
  &__realmoneyType {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-Neutral-3);
    padding: 24px 16px;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &--description {
      @extend %description;
    }
    &--title {
      @extend %label-lg;
      margin-bottom: 4px;
    }
    &--coins {
      display: flex;
      align-items: center;
      i {
        margin-left: -8px;
        height: 24px;
      }
      span {
        margin-left: 8px;
      }
    }
    &:first-of-type {
      margin-bottom: 16px;
    }
    &:hover {
      border: 1px solid var(--color-primary-pure);
      transition: 200ms;
    }
  }
  &__content__container {
    display: flex;
    overflow: hidden;
    gap: 72px;
    @media screen and (max-width: 768px) {
      gap: 40px;
    }
    margin: 0 !important;
  }

  &__currency_list,
  &__currency_list--noAnim {
    overflow: hidden;

    .view-crypto-cash-in__content {
      &__container {
        @media screen and (max-width: 768px) {
          width: 200vw;
          gap: 28px;
        }
      }
      padding-bottom: 0;
      width: 100%;
      margin-bottom: 0em;
      display: flex;
    }
    .view-crypto-cash-in__currency_list--info {
      height: unset;
      @media screen and (max-width: 768px) {
        padding-bottom: 120px;
      }
    }
  }
  &__content {
    width: 100%;
    // margin-bottom: 1.5em;
    // max-height: 60vh;
    // overflow: auto;
    svg {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      &-top {
        margin-top: 1rem;
        margin-bottom: 24px;
      }
    }
  }
  &__header {
    display: flex;
    gap: 6px;
    align-items: center;
    color: var(--color-Neutral-6);
  }
  .action-sheet__close-button {
    color: var(--color-Neutral-6);
  }
  &__kyc-panel {
    margin-left: auto;
    margin-right: auto;
  }

  &__paragraph {
    background-color: #ffd3ec;
    color: black !important;
    align-items: center;
    display: flex;
    gap: 17px;
    border-radius: 4px;
    padding: 17px;
    font-size: 14px !important;
    font-weight: 400 !important;
    svg {
      color: $color-pink;
    }
  }
  &--divider {
    margin-top: 24px;
  }
  &--fiat-modal {
    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      @media screen and (max-width: $desktop) {
        margin-top: auto; 
        button {
          width: 100%;
        }
      }
      &--method {
        display: flex;
        gap: 8px;
        flex-grow: 1;
        align-items: center;
        p {
          max-width: 300px;
        }
        &--logo {
          padding: 12px;
          background-color: var(--color-Neutral-3);
          border-radius: 8px;
          display: flex;
        }
      }
    }
  }
  &__text-input {
    @extend %typography-label-input;
    &.filter-dropdown-control {
      &--open,
      &--selected {
        border-bottom: 1px solid var(--color-primary-pure);
      }
    }
    width: 100%;
    border: 1px solid var(--color-Neutral-3);
    height: 56px;
    border-radius: 8px;
    padding: 0 16px;
    .filter-dropdown-control {
      &__value {
        border: none;
        padding: 0;
        justify-content: space-between;
        color: black;
        text-transform: capitalize;
        background-color: unset !important;
        box-shadow: none !important;
        &:hover {
          background-color: unset;
        }
      }
      &__placeholder {
        color: rgba($color: #000000, $alpha: 0.5);
        font-size: 16px;
        font-weight: 400;
      }
      &__list {
        right: 0;
        left: unset;
      }
      &__arrow {
        margin: 0 1rem 0;
      }
    }
  }

  &__deposit_status {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: var(--color-Neutral-6);
    i {
      background-color: var(--color-primary-low);
      padding: 16px;
      border-radius: 100%;
      display: flex;
    }
    &--title {
      @extend %heading-xs;
    }
    &--description {
      @extend %paragraph-sm;
      text-align: center;
      .link {
        font-size: 16px !important;
      }
      b {
        font-weight: 600;
      }
    }
    button {
      margin-top: 16px;
    }
    &--buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__small {
    color: $color-shade-2;
    @extend %typography-thumb-caption;
    font-family: 'Lato';
  }

  &__dropdown {
    margin-bottom: 2em;
  }
  &__select_crypto_as_mode {

    &--label {
      margin-bottom: 8px;
      label {
        @extend %label-md;
        font-weight: 600;
        color: var(--color-Neutral-6);
      }
    }
    &--breadcrumb {
      margin-bottom: 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--color-Neutral-4);
      @extend %label-rg;
      .link {
        color: var(--color-Neutral-4);
        cursor: pointer;
        font-weight: 400;
      }
      span {
        &:last-of-type {
          color: var(--color-Neutral-6);
          @extend %label-md;
          font-weight: 600;
        }
      }
    }
    
  }
  &__select{
    hr{
      display: none;
    }
    .px-2\.5{
      padding: unset;
    }
  }
  &__crypto-dropdown {
    height: 56px;
    display: flex !important;
    align-items: center;
    border-radius: 8px !important;
    justify-content: space-between;
    padding-right: 18px !important;
    &:hover {
      background-color: var(--color-Neutral-2) !important;
      border-color: var(--color-Neutral-2) !important ;
    }
    &.active {
      & > svg {
        transform: rotate(180deg) !important;
      }
    }
    &.filter-dropdown-control,
    .filter-dropdown-control {
      &--open {
        .filter-dropdown-control__value {
          background-color: unset !important;
          color: unset;
          box-shadow: unset;
        }
      }
      &--selected {
        .filter-dropdown-control__value {
          background-color: unset !important;
          color: unset;
          box-shadow: unset;
          &:hover {
            background-color: unset;
          }
        }
      }
      &__value {
        width: 100%;
        border: 1px solid var(--color-Neutral-3);
        height: 56px;
        justify-content: space-between;
        span {
          color: var(--color-Neutral-4);
          @extend %label-md;
        }
      }
      &__list {
        left: 0;
        top: 68px;
        width: 100%;
        border-radius: 8px;
        li {
          padding: 24px;
          &:hover {
            color: unset;
            background-color: unset;
          }
        }
      }
    }

    input {
      height: 100%;
    }
    //   top: 100px !important;
    //   margin: 0 24px;
    //   z-index: 111;
    //   display: flex;
    //   height: 56px;
    //   padding: 0px var(--spacing-3-x-24-px, 24px);
    //   justify-content: space-between;
    //   align-items: center;
    //   border-radius:500px;
    //   background: var(var(--color-Neutral-2), #edefed) !important;
    // }
    .divider.text {
      display: flex !important;
      gap: 8px;
      align-items: center;
    }
    i {
      top: 21px !important;
    }
    .menu {
      top: 68px !important;
      margin-top: unset !important;
      border-radius: 8px !important;
      .item {
      }
    }
    &.ui.active.selection.dropdown {
      border-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    &.ui.floating.dropdown > .menu {
      border-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    &.ui.selection.active.dropdown {
      box-shadow: unset !important;
    }
    .default {
      display: flex !important;
      gap: 8px;
      align-items: center;
      svg {
        opacity: 0.5;
      }
    }
    &.ui.selection.dropdown .menu > .item {
      padding: 24px !important;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: var(--color-Neutral-2);
      color: var(--color-Neutral-6);
      border-color: var(--color-Neutral-1);
    }
    &.ui.selection.active.dropdown .menu {
      border-color: var(--color-Neutral-1);
      box-shadow: unset;
    }
  }

  &__qrcode {
    width: 100%;
    display: flex;
    margin-top: 24px;
    background-color: var(--color-Neutral-2);
    float: right;
    align-items: center;
    padding: 24px;
    gap: 16px;
    border-radius: 8px;
    @media screen and (max-width: $desktop) {
      flex-direction: column-reverse;
    }
    p {
      margin-bottom: 12px;
      color: var(--color-Neutral-4);
    }
    &-container {
      border-radius: 8px;
      display: flex;
      padding: 8px;
      background-color: white;
    }
    &--adress {
      display: flex;
      gap: 16px;
      svg {
        cursor: pointer;
      }
      &--pix {
        width: 100%;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 132%;
        color: var(--color-Neutral-6);

        line-break: anywhere;
      }
    }
  }

  &__label {
    @extend %typography-label;
    color: $color-shade-0;
  }

  &__close {
    position: absolute;
    right: 21px;
    top: 18px;
    cursor: pointer;
    color: var(--color-Neutral-6);
  }

  &__title {
    font-family: 'Lato';
  }
  &__link {
    color: var(--color-primary-pure);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    display: block;
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    button {
      height: 40px;
    }
  }
  &__footer {
    margin-bottom: 160px;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      display: grid;
      justify-items: center;
      gap: 30px;
    }
  }
  &__fees {
    color: $color-shade-2;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0.5rem;
    background: var(--color-Neutral-3);
    margin-top: 2.25em;
    border-radius: 0 0 10px 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    &-btn {
      color: var(--color-primary-pure);
      cursor: pointer;
    }
  }
  .tooltip {
    left: 65px;
    top: 12px;
    z-index: 90;
    display: none;
  }
  &__tooltip {
    color: var(--color-primary-pure);
    cursor: pointer;
    padding: 3px;
    &:hover {
      & + .tooltip {
        display: block;
      }
    }
  }

  &__tooltip-indicator {
    top: 11px;
    left: 20px;
  }

  @media screen and (min-width: $tablet) {
    &__content-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5em;
    }

    &__dropdown {
      margin-bottom: 0;
      margin-top: 1.8em;
    }

    h2 {
      max-width: 100%;
    }
  }
  &__pixOrAstropay {
    display: flex;
    gap: 24px;

    @media screen and (max-width: $desktop) {
      flex-direction: column;
      gap: 16px;
    }

    &--option {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--color-Neutral-4);
      margin-bottom: 24px;
      @media screen and (max-width: $desktop) {
        margin-bottom: unset;
      }
      &--title {
        display: flex;
        gap: 8px;
        align-items: center;
        @extend %label-md;
        font-weight: 600;
        color: var(--color-Neutral-6);
      }
      &--description {
        @extend %description;
        color: var(--color-Neutral-4);
      }
      &--selected {
        border: 1px solid var(--color-primary-pure) !important;
      }
      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        border: 1px solid var(--color-primary-low);
      }
    }
    &--document {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;
      .field__input {
        max-width: 76px;
      }
      & > div {
        width: 100%;
      }
    }
  }
  &__missingData {
    &__title {
      font-size: 20px;
      margin: 0;
      font-weight: 600;
    }
    &__description {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-Neutral-5);
      margin: 24px 0;
    }
    &--grid {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      gap: 24px;
      .countryOfResedence {
        .ui.selection.active.dropdown {
          box-shadow: unset;
        }

        .ui.selection.dropdown {
          @extend %form-text-field;
        }
        input {
          height: 100%;
        }
        .dropdown {
          display: flex !important;
          flex-direction: column;
          justify-content: center;
          height: 56px;
        }
        &:hover {
          .dropdown {
            background-color: var(--color-Neutral-3) !important;
          }
        }
      }

      .datePicker {
        position: relative;
        height: 56px;
        &:focus-within,
        &__open {
          .text-field-el__label {
          }
        }
        .react-date-picker__wrapper {
          display: flex;
          align-items: center;
        }
        .text-field-el__label {
          z-index: 2;
          pointer-events: none;
        }
        &:hover {
          .react-date-picker__wrapper {
            background-color: var(--color-Neutral-3) !important;
          }
        }
        .react-date-picker {
          height: 56px;
          input {
            outline: none;
            padding: 0;
            border: none;
            background-color: transparent;
            color: var(--color-Neutral-6);
          }
          .hide_calendar {
            display: none;
          }
          &--disabled {
            background: unset;
          }
          &__inputGroup__input:invalid {
            background: none;
          }
          width: 100%;
          &__wrapper {
            @extend %form-text-field;
          }
        }
      }
      &__submit {
        grid-column: 1 / 3;
        button {
          height: 44px;
          float: right;
        }
      }
    }
  }
  &__currency_list--info {
    width: 100%;
    height: 0;
    transition: 300ms;
    p {
      font-weight: 400;
    }
    ul {
      padding: 0;
      max-height: 60vh;
      @media screen and (max-width: $desktop - 1) {
        max-height: unset;
      }
      overflow: auto;
      li {
        padding: 1rem;

        @media screen and (max-width: 768px) {
          padding-left: 0;
        }
        display: flex;
        margin-top: 0.5rem;
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #ebebeb;
          transition: 300ms;
        }
      }
    }
  }
}

.astropay-backdrop {
  z-index: 3000 !important;
  background-color: unset !important;
}
.jss7 {
  width: 560px;
  border-radius: 19px;
  contain: content;
}
