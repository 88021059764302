@import '../styles/global';

.image {
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;

    &--loading {
      opacity: 0;
      transition: none;
    }

    &--error {
      display: none;
    }

    &--animate {
      transition: opacity $animation-slow;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;

    &--loading {
      opacity: 0;
      transition: none;
    }

    &--error {
      display: none;
    }

    &--animate {
      transition: opacity $animation-slow;
    }
  }
}
