@keyframes animate {
  0% {
    d: path(
      'M 0.501 15.871 H 3.8333 V 16.5 H 0.5 V 15.916 Z M 12.18 15.511 H 15.5 V 16.5 H 12.1667 V 15.534 Z M 6.318 15.803 H 9.6667 V 16.5 H 6.3333 V 15.781 Z'
    );
  }
  45% {
    d: path(
      'M 0.5 9 H 3.8333 V 16.5 H 0.5 V 9 Z M 12.179 8.999 H 15.5 V 16.5 H 12.1667 V 8.999 Z M 6.363 8.954 H 9.6667 V 16.5 H 6.3333 V 8.954 Z'
    );
  }
  55% {
    d: path(
      'M 0.5 9 H 3.8333 V 16.5 H 0.5 V 9 Z M 12.1667 5.6667 H 15.5 V 16.5 H 12.1667 V 5.6667 Z M 6.33 5.724 H 9.6667 V 16.5 H 6.3333 V 6.108 Z'
    );
  }
  100% {
    d: path(
      'M0.5 9H3.83333V16.5H0.5V9ZM12.1667 5.66667H15.5V16.5H12.1667V5.66667ZM6.33333 0.666668H9.66667V16.5H6.33333V0.666668Z'
    );
  }
}
@-webkit-keyframes animate {
  0% {
    d: path(
      'M 0.501 15.871 H 3.8333 V 16.5 H 0.5 V 15.916 Z M 12.18 15.511 H 15.5 V 16.5 H 12.1667 V 15.534 Z M 6.318 15.803 H 9.6667 V 16.5 H 6.3333 V 15.781 Z'
    );
  }
  45% {
    d: path(
      'M 0.5 9 H 3.8333 V 16.5 H 0.5 V 9 Z M 12.179 8.999 H 15.5 V 16.5 H 12.1667 V 8.999 Z M 6.363 8.954 H 9.6667 V 16.5 H 6.3333 V 8.954 Z'
    );
  }
  70% {
    d: path(
      'M 0.5 9 H 3.8333 V 16.5 H 0.5 V 9 Z M 12.1667 5.6667 H 15.5 V 16.5 H 12.1667 V 5.6667 Z M 6.33 5.724 H 9.6667 V 16.5 H 6.3333 V 6.108 Z'
    );
  }
  100% {
    d: path(
      'M0.5 9H3.83333V16.5H0.5V9ZM12.1667 5.66667H15.5V16.5H12.1667V5.66667ZM6.33333 0.666668H9.66667V16.5H6.33333V0.666668Z'
    );
  }
}
.question-card__general-info-left:hover .question-card__volume-text path {
  // animation-name: animate;
  // -webkit-animation-name: animate;
  // -webkit-animation-timing-function: ease-out;
  // animation-timing-function: ease-out;
  // skeleton-animation-duration: 400ms;
  // -webkit-skeleton-animation-duration: 400ms;
}
