@import 'common/styles/global';

.error-handler {
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 65vh;
  &__bigger{
    height: 75vh;

  }
  overflow: auto;
  &__icon {
    color: $color-pink-text;
    font-size: 23px;
    margin-bottom: 20px;
    > svg {
      margin: 6px auto;
      display: block;
    }
  }
  &__full_error {
    background: #b982823b;
    color: var(--color-page-error);
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #c10000;
    max-width: 70%;
    text-align: start;
    b {
      font-size: 20px;
    }
  }
  &__more-option {
    &, span {
      // color: var(--color-Neutral-4) !important;
      font-size: 11px !important;
    }
  }
  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    text-align: center;
    color: var(--color-Neutral-6);
    gap: 24px;
    font-size: 16px;

    p {
      max-width: 500px;
      font-weight: 300;
      color: var(--color-Neutral-6);
      b {
        font-size: 20px;
        line-height: 150%;
        font-weight: 700;
      }
    }

    span {
      font-size: 50px;
      color: var(--color-Neutral-6);
    }
  }

  .button--error {
    height: 50px;
    line-height: 50px;
    width: 500px;
    @media screen and (max-width: 500px) {
      width: 50vw;
    }
    background-color: var(--color-brand-02);
    color: var(--color-Neutral-6);
    transition: 500ms;

    &:hover {
      background-color: var(--color-brand-03);
      color: var(--color-Neutral-6);
    }
  }
}
.feedError {
  text-align: center;
  font-size: 15px;
  color: #4c626d;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45vh;
    justify-content: center;
  }
  &__tooltip {
    position: relative;
    width: 100px;
    height: 47px;
    display: flex;
    justify-content: center;
    background: var(--color-Neutral-1);
    border-radius: 7px;
    box-shadow: 2px 9px 20px #00000012;
    align-items: center;
    &__image {
      width: 36px;
      height: 36px;
      background-size: cover;
      background-image: url('../../../landing/assets/bug.png');
    }
    i.tooltip__arrow {
      left: 45%;
      bottom: -5px;
      width: 10px;
      height: 10px;
      top: auto;
      background-color: var(--color-Neutral-1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      border-right: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  .button--error {
    height: 32px;
    line-height: 32px;
    background-color: $color-pink-text;
    color: var(--color-Neutral-1);
    padding: 0rem 38px;
  }
}
.error-handler {
  background: var(--color-surface-1);
  color: var(--color-Neutral-6);
}
