#countryOfResidenceDropdown{
  min-height:55px;
  border-radius: 8px;
  border: 0.1em solid var(--color-Neutral-3);
  display: flex;
  align-items: center;
  background-color: var(--color-Neutral-1);
  color: var(--color-Neutral-6);
}

#countryOfResidenceDropdown:hover{
  background-color:var(--color-Neutral-3);
}

#countryOfResidenceDropdown .search{
  height:100%;
}

#countryOfResidenceDropdown i.dropdown {
  height: 100%;
  display: flex;
  align-items: center;
}

#countryOfResidenceDropdown .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
  color: rgb(156 163 175);
}
