@import 'declarations';
@import 'helpers';

%clearfix {
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

%button-transparent {
  @include tap-hightlight-color($color-white-25);

  padding: 0;
  border: 0;
  margin: 0;
  appearance: none;
  background-color: $color-white-transparent;
  background-repeat: no-repeat;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @if $is-desktop {
    &:hover {
      background-color: $color-white-10;
    }
  }
}

%tag {
  color: $color-grey4;
  font-family: $font-tag;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}

%question-list {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media screen and (min-width: $desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 23px;
  }
}

%full-page-height {
  min-height: 100vh;
  @media screen and (min-width: $tablet-wide) {
    min-height: calc(100vh - 80px);
  }
}

%page-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

%page-container,
%page-container__from-tablet {
  @media screen and (min-width: $tablet) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

%page-container,
%page-container__from-tablet,
%page-container__from-desktop {
  @media screen and (min-width: $desktop) {
    padding-left: 0;
    padding-right: 0;
    max-width: 960px;
  }

  @media screen and (min-width: $widescreen) {
    max-width: 1192px;
  }
}

%comment-mention {
  color: $color-blue;
  font-weight: bold;
  transition: 100ms ease-in color;
  vertical-align: baseline;
  margin-right: 0.25em;

  &:hover {
    color: $color-light-blue;
  }
}
