@import 'common/styles/global';
@import 'common/styles/zindex';

@keyframes rightToLeft {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
.phone-page {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;

  animation: rightToLeft 100ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  background-color: var(--color-Neutral-1);
  overflow: auto;
  &-header {
    padding: 0 24px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      position: absolute;
      left: 24px;
      color: var(--color-Neutral-6);
    }
    h2 {
      @extend %heading-xxs;
    }
  }
}
