@import '../styles/global';

.field {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  margin: 8px 0;

  &--disabled {
    opacity: 0.4;
  }

  &__label {
    color: $color-black-60;
    font-size: 12px;
  }

  &__error {
    flex-grow: 1;
    color: $color-pink-text-60;
    font-size: 12px;
    opacity: 0;
    text-align: right;
    transition: transition($animation-slowy, opacity);

    .field--invalid & {
      opacity: 1;
    }
  }

  .flex__break-line {
    margin-bottom: 4px;
  }

  &__input {
    flex: 1;
    padding: 4px;
    border: 0;
    margin: 0;
    appearance: none;
    background: transparent;
    border-radius: 0;
    color: $color-grey5;
    font-size: 14px;
    outline: 0;

    &:-webkit-autofill {
      box-shadow: inset 0 14px 28px var(--color-Neutral-1);
    }
  }

  &__icon {
    height: 18px;
    padding-right: 4px;
    fill: $color-grey2;
    color: $color-grey2;
    transition: transition($animation-normal, fill);

    .field--invalid & {
      fill: $color-pink-text;
    }

    .field__input:focus ~ & {
      fill: var(--color-primary-pure)-text;
    }
  }

  &__line {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $color-grey3-50;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      border-bottom: 2px solid $color-grey3-50;
      content: '';
      transition: transition($animation-normal, border, width, left);

      .field--invalid & {
        width: 100%;
        border-bottom: 2px solid $color-pink-text;
      }

      .field__input:focus ~ & {
        width: 100%;
        border-bottom: 2px solid var(--color-primary-pure)-text;
      }
    }
  }

  &--image {
    position: relative;
    overflow: hidden;

    &--required {
      border: 1px solid $color-red;
    }

    &--invalid {
      border: 1px solid $color-red;
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }

    &__preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
