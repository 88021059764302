@import 'common/styles/global';

.question-details {
  background-color: var(--color-surface-1);

  font-size: 14px;
  height: 100%;
  overscroll-behavior: none;
  padding: 16px;
  @media screen and (min-width: $desktop) {
    padding: 0;
  }
  min-height: calc(100vh - 80px);
  -webkit-overflow-scrolling: touch;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  &--loading {
    align-items: center;
    display: flex;
    height: calc(100vh - 80px);
    justify-content: center;
    width: 100%;
    background-color: var(--color-surface-1);
  }

  &__heading {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }

  &__description {
    padding: $list-item-margin;

    .interweave {
      margin: 10px 0 0;
      line-height: 20px;
      word-wrap: break-word;

      > *:last-child {
        margin-bottom: 0;
      }

      pre {
        font-family: $font-main;
        white-space: normal;
      }
    }
  }

  &__share {
    width: 20px;
    margin-right: 23px;
  }

  &__social-list {
    border-left: 1px solid $color-grey3-50;
    list-style: none;
    display: inline-flex;
    padding: 0 0 0 23px;
    width: calc(100% - 43px);
    justify-content: flex-start;
  }

  &__social-item {
    width: 26px;
    height: 26px;
    margin-right: 23px;
    cursor: pointer;

    &--facebook {
      background-image: url('/social/assets/icon-facebookCircle.svg');
    }

    &--copylink {
      background-image: url('/social/assets/icon-copylinkCircle.svg');
    }

    &--twitter {
      background-image: url('/social/assets/icon-twitterCircle.svg');
    }

    &--whatsapp {
      background-image: url('/social/assets/icon-whatsappCircle.svg');
    }

    &--plus {
      background-image: url('/social/assets/icon-plusCircle.svg');
    }
  }

  &__comments {
    padding: $list-item-margin;
  }

  &__sidebar {
    display: none;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--color-Neutral-1);
    border-radius: 8px;
    padding: 24px;
    margin-top: 16px;

    &__title {
      margin: 30px 0 0;
      color: $color-grey5;
      font-size: 60px;
      font-weight: $font-weight-thin;
      line-height: 48px;

      strong {
        font-weight: $font-weight-extrabold;
      }
    }
    .banner-grid-intro__text {
      width: 100%;
    }

    &__about {
      margin: 20px 0 0;
      color: $color-grey3;
      font-size: 18px;
    }

    &__fb {
      width: 260px;
      height: 50px;
      border: 0;
      margin-top: 30px;
      background-color: var(--color-primary-pure)-facebook;
      border-radius: 4px;
      color: var(--color-Neutral-1);
      font-size: 14px;
      font-weight: $font-weight-semibold;
      outline: 0;
      text-transform: uppercase;
    }

    &__line {
      width: 100%;
      border: 1px solid $color-grey1;
      margin: 30px 0;
    }

    &__links {
      padding: 0;
      color: var(--color-primary-pure);
      font-size: 14px;
      font-weight: $font-weight-semibold;
      list-style: none;
      text-transform: uppercase;

      > li {
        margin-bottom: 25px;

        &::before {
          margin-right: 15px;
          content: '\25B6';
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .question-details-video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;

    &.active {
      display: block;
    }

    &__modal {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 100;
    }

    &__overlay {
      background-color: rgba(255, 255, 255, 0.98);
      width: 100vw;
      height: 100vh;
      position: absolute;
    }

    &__content {
      position: relative;
      padding-bottom: 55%; // This is the aspect ratio
      height: 0;
      overflow: hidden;

      a {
        display: block;
        height: 45px;
        width: 45px;
        z-index: 100;
        background-image: url('/landing/assets/icon-close.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 100px;
        right: 20px;
        span {
          display: none;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 80% !important;
        height: 80% !important;
        margin: auto;
        @media only screen and (max-width: $desktop) {
          width: 100% !important;
          margin-top: 80px;
        }
      }
    }
  }
  &__aside {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    position: sticky;
    top: 126px;
    padding-top: 54px;
    @media only screen and (max-width: $desktop) {
      position: fixed;
      bottom: 0;
      left: 0; 
      z-index: 102;
      top: unset;
      width: 100%;
      max-width: unset;
      border-radius: 8px 8px 0 0;
    }
    &--closed {
      .place-bet__header {
        svg {
          transform: rotate(0);
        }
      }
      z-index: 99;
      .place-bet__content {
        opacity: 0;
        padding: 0;
        height: 0px;
        border: 0;
        transition: 500ms cubic-bezier(0.005, 0.75, 0.25, 1);
      }
      .place-bet__header {
        svg {
          transition: 500ms cubic-bezier(0.005, 0.75, 0.25, 1);

          transform: rotate(180deg);
        }
      }
      &--tabBarUp {
        .place-bet__content {
          height: 78px;
        }
      }
    }
  }
  .question-card {
    &__maindetails {
      border: none;
      overflow: visible;
      margin-bottom: 16px;
      border-radius: 8px;
      .question-header__title {
        max-width: unset;
      }
    }

    &__general-info {
      margin-top: 34px;
      @media screen and (max-width: #{$desktop - 1px}) {
        margin-top: 20px;
      }
    }
    &__bet-status {
      margin-bottom: 16px;
      border-radius: 8px;
    }
  }

  &__question-card {
    @media screen and (min-width: 768px) {
      width: 696px;
    }
    &--back {
      margin-bottom: 16px;
      cursor: pointer;
    }
  }
  .bet_status_title {
    display: unset;
  }
}

@media (min-width: $tablet-wide) {
  .question-details {
    padding-top: 30px;
  }
}

@media (min-width: $desktop) {
  .question-details {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 0 60px;
    gap: 16px;
    .question-card__compact > .question-card__main {
      border: 1px solid var(--color-Neutral-3);
    }
    .question-card {
      max-width: 690px;
      margin: 0 auto;
    }

    &__sidebar {
      display: block;
      width: 340px;
    }
  }
}
