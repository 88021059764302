@import 'common/styles/global';

#modal-root {
  .modal__overlay {
    align-items: center;
    background: rgba(#12113a, 0.64);
    bottom: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow-y: auto;
    // overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 40px 25px;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 2000;
    @media screen and (max-width: $desktop - 1) {
      padding: 0;
    }
  }
  .modal__overlay.dark {
    background: rgba($color-grey2, 0.8);
  }

 
  .modal__overlay--full,
  .modal__overlay--bottom {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    bottom: 0;
    top: auto;
    .modal__content {
      bottom: 0;
      margin: 100px 0 0;
      max-width: 690px;
      // min-height: calc(100vh - 100px);
      position: relative;
      @media screen and (max-width: $desktop - 1) {
        // position: fixed;
        display: grid;
      }
      width: 100%;
      -webkit-overflow-scrolling: touch;
    }
  }

  .modal__overlay--full {
    .modal__content {
      top: 0;
      margin-top: 0;
      min-height: 100vh;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .modal__content {
    cursor: auto;
    margin: auto;
    pointer-events: none;
    @media screen and (max-width: $desktop - 1) {
      margin: 0;
      width: 100%;
      height: 100%;
    }
    & > div,
    & > article {
      pointer-events: auto;
    }
  }

  @media screen and (min-width: 600px) {
    .modal__overlay--bottom {
      justify-content: center;

      .modal__content {
        height: auto;
        bottom: auto;
        min-height: calc(100vh - 100px);
        position: absolute;
        margin: 0 auto;
      }
    }
  }
}
