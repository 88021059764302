@import 'common/styles/global';

.view-tags {
  background-color: var(--color-surface-1);
  @extend %full-page-height;

  &__header {
    padding-top: 30px !important;
    margin-bottom:64px;

    &__title {
      font-family: $font-main;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 40px;
      margin: 0;
      margin-top: 19px;
    }
    .breadcrumb__item {
      font-family: $font-main;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 14px;
      color: #666;
    }
  }
}
