@import 'common/styles/global';
@import 'common/styles/zindex';

.messagebar {
  top: 0;
  width: 100vw;
  position: fixed;
  z-index: 999999;
  transition: 0.5s $notification-easing transform, 0.5s $notification-easing opacity;
  transform: translateY(0);
  opacity: 1;

  &--empty {
    transform: translateY(-100%);
    opacity: 0;
  }

  &__content {
    background-color:var(--color-Neutral-1);
    height: 75px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px 0 rgba($color-black, 0.3);
    margin-bottom: 12px;
    padding: 0 20px;
  }

  &__close {
    display: none;
  }
}

.message {
  display: flex;
  align-items: flex-start;
  &--icon{
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center; 
    border: 1px solid var(--color-Neutral-6);
    border-radius: 100%;
    color: var(--color-Neutral-6);
  }
}

.message__text {
  margin: 0 20px;
  flex: 1 0 115px;

  em {
    font-style: normal;
    font-weight: $font-weight-semibold;
  }
  b {
    font-weight: 600;
  }
  &.WagerBuy,
  &.WagerSell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // @media screen and (min-width: $desktop - 1) {
  //   &.WagerBuy,
  //   &.WagerSell {
  //     width: 314px;
  //   }
  // }
}
.message__main--second-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-Neutral-4);
  margin-top: 4px;
  
  em,
  .currency__symbol {
    color: var(--color-Neutral-5);
  }
  .currency__symbol {
    width: fit-content;
    display: flex;
    gap: 3px;
    align-items: center
  }
}
.message__main-text {
  @extend %heading-xxs;
  color: var(--color-Neutral-5);

  a {
    color: var(--color-primary-pure);
  }
}

.message__sub-text {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #7f7f7f;

  a {
    @extend %label-lg;
    font-weight: 600;
    color: var(--color-primary-pure);
    &:hover {
      color: var(--color-primary-high);
    }
  }
}

.message__icon {
  color: var(--color-primary-pure);
}

.message__icon--share {
  width: 25px;
}

.message__icon--arrow {
  width: 6px;
  transform: scaleX(-1);
}

.message__image {
  height: 64px;
  flex-shrink: 0;
}

.message__image--square {
  border-radius: 4px;
  width: 55px;
}

.message__image--round {
  border-radius: 8px;
  width: 80px;
  height: 80px;
}

@media (min-width: $desktop) {
  .messagebar {
    &__content {
      height: 80px;
      justify-content: space-between;
      cursor: pointer;
    }

    &__close {
      display: block;
      background: none;
      border: 0;
      outline: 0;
      appearance: none;
      color: $color-grey4;
    }
  }

  .message {
    justify-content: center;
    
  }

  .message__text {
    margin: 0 16px;
  }
}
