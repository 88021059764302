@import 'declarations';
@import 'button';
@import 'transitions';

* {
  box-sizing: inherit;

  &::after {
    box-sizing: inherit;
  }

  &::before {
    box-sizing: inherit;
  }

  &:not(input):not(textarea) {
    white-space: normal;
  }
}

html,
body {
  /* stylelint-disable-line */
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;

  
  margin-top: constant(safe-area-inset-top);
	margin-top: env(safe-area-inset-top);

}

body {
  @include tap-hightlight-color($color-white-transparent);
  @include font-smoothing(antialiased);

  box-sizing: border-box;
  margin: 0;
  background-color: var(--color-Neutral-1);
  overflow-y: scroll;
}

body,
input,
button {
  font-family: $font-main;
}

::selection {
  background: $color-light-blue;
  color: var(--color-Neutral-1);
}

a {
  @include user-drag(none);

  color: inherit;
  text-decoration: none;
}

a,
button,
input {
  // Prevent 350ms delay
  touch-action: manipulation;
}

.font-preload {
  position: absolute;

  &__1 {
    font-weight: bold;
  }

  &__2 {
    font-weight: $font-weight-extrabold;
  }

  &__3 {
    font-weight: $font-weight-light;
  }

  &__4 {
    font-weight: $font-weight-semibold;
  }

  &__5 {
    font-family: $font-title;
    font-weight: bold;
  }

  &__6 {
    font-family: $font-currency;
  }
}

.hidden {
  display: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

 

.heading {
  $height: 30px;

  @extend %tag;

  width: 100%;
  height: $height;
  padding: 0 $list-item-margin;
  border-top: 1px solid $color-grey1;
  border-bottom: 1px solid $color-grey1;
  margin: 0;
  line-height: $height;
  text-overflow: ellipsis;
}

.flex {
  &--horizontal {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  &__spacer {
    flex: 1 10;
    flex-basis: 0;
    text-align: center;

    &--line {
      height: 0;
      flex: 1;
      border-bottom: 1px solid $list-separator-color;
      margin: 0 16px;
    }
  }

  &__break-line {
    width: 100%;
    flex: 1 0 100%;
    line-height: 0;
  }
}

.table {
  $padding: $list-item-margin;

  width: 100%;
  padding: $padding 0;
  border: 0;
  border-spacing: 0;
  text-align: left;

  %table-row {
    &::after,
    &::before {
      display: table-cell;
      width: $padding;
      content: '';
    }
  }

  &__row {
    @extend %table-row;

    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    &--head {
      @extend %table-row;
    }

    &--group {
      @extend %table-row;
    }
  }

  %table-cell {
    border-bottom: 1px solid $color-grey1;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__cell {
    @extend %table-cell;

    padding: 12px 4px;
    color: $color-grey5;
    font-size: 11px;

    &--head {
      @extend %table-cell;
      @extend %tag;

      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 8px;
    }

    &--group {
      @extend %table-cell;
      @extend %tag;

      padding: 16px 4px 8px;
    }
  }

  &__link {
    color: var(--color-primary-pure);
    font-weight: bold;
  }

  &__text {
    &--sub {
      color: $color-grey3;
      font-size: 9px;
    }
  }
}
