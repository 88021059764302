@import 'common/styles/global';

.email-login {
  &__button {
    position: relative;
  }

  &__icon {
    color: var(--color-primary-pure);
    position: absolute;
    left: 14px;
    top: 14px;
  }
}
