@import 'common/styles/global';
@import 'common/styles/zindex';
.filter-dropdown-panel {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 4px;
  gap: 4px;

  // @media screen and (max-width: $desktop) {
  //   overflow-y: auto;
  // }
  li {
    @media screen and (max-width: $desktop) {
      flex: 1;
    }
    .filter-dropdown-control {
      // &--selected .filter-dropdown-control__value {
      //   background-color: var(--color-primary-pure);
      //   color: var(--color-contrast-01);
      //   border-color: var(--color-primary-pure);

      //   &:hover {
      //     background-color: var(--color-primary-high);
      //     color: white;
      //   }
      // }
    }
  }
}
