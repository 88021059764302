@import 'common/styles/global';
@import 'common/styles/_zindex';

.category-navigation {
  // Main categories navigation styles
  .flex.overflow-x-auto {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  // Swiper specific styles (for subcategories only)
  .swiper {
    width: 100%;
    padding: 0 16px; // Add padding to match the previous !px-4 class
    @media screen and (max-width: 768px) {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: auto !important; // Force auto width for slides
    height: auto;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  &--subcategories {
    .swiper {
      padding: 0 16px;

      @media (min-width: 768px) {
        padding: 0 30px; // Match the previous md:!px-[30px] class
      }
    }

    .swiper-wrapper {
      justify-content: flex-start;
    }

    .swiper-slide {
      margin-right: 8px; // Add gap between subcategory items
    }
  }

  .sliding-border {
    height: 2px;
    border-radius: 1px;
    pointer-events: none;
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 1;

    @media (max-width: 767px) {
      display: none; // Hide sliding border on mobile
    }
  }

  .active-nav-link {
    position: relative;
    border-bottom: 2px solid transparent; // Transparent border to maintain height consistency

    @media (max-width: 767px) {
      border-bottom: 2px solid var(--color-brand-04); // Solid border for active link on mobile
    }
  }

  // Styles for category ellipsis
  .category-ellipsis {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.9;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// Make swiper containers scrollbar-hide
.swiper-container {
  @extend .scrollbar-hide;
}
