.helmet-market-card-image {
  .category-list-parent,
  .question-header__market-state,
  .question-header--rightIcons {
    display: none;
  }
  .question-header__title {
    max-width: unset;
  }
  .question-card__section {
    border-radius: 8px;
  }
//   .outcome-item__chart {
//     transition-duration: 0ms;
//   }
}
