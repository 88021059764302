@import 'declarations';

// Utility functions -----------------------------------------------------------

/** Apply the same transition configuration to multiple props */
@function transition($config, $props...) {
  $output-list: ();

  @each $prop in $props {
    $output-list: append($output-list, $prop $config, comma);
  }

  @return $output-list;
}

/** Remove the unit of a length */
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// Prefixed properties ---------------------------------------------------------

@mixin box-orient($direction) {
  /*! autoprefixer: off */
  -webkit-box-orient: $direction; // stylelint-disable-line property-no-vendor-prefix
}

@mixin overscroll-behavior($mode) {
  overscroll-behavior: $mode; // stylelint-disable-line property-no-unknown
}

@mixin overflow-scrolling($mode: touch) {
  -webkit-overflow-scrolling: touch;
}

@mixin tap-hightlight-color($color) {
  -webkit-tap-highlight-color: $color;
}

@mixin user-drag($mode) {
  -webkit-user-drag: $mode;
}

@mixin font-smoothing($smoothing) {
  -webkit-font-smoothing: $smoothing;
}

// Helpers ---------------------------------------------------------------------

@mixin line-clamp($line-height, $max-lines) {
  // TODO: Remove webkit-box
  @include box-orient(vertical);

  display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
  overflow: hidden;
  max-height: $line-height * $max-lines;
  -webkit-line-clamp: $max-lines;
  line-height: $line-height;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hide-scrollbar {
  overflow: -moz-scrollbars-none; // Firefox
  -ms-overflow-style: none; // IE 10+

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin auto-margin($max-width, $min-margin) {
  max-width: $max-width;
  box-sizing: content-box;
  padding-right: $min-margin;
  padding-left: $min-margin;
  margin-right: auto;
  margin-left: auto;
}

@mixin glass-effect($color: $color-white-ios, $blur: 20px) {
  @supports (backdrop-filter: blur($blur)) {
    backdrop-filter: blur($blur);
    box-shadow: 0 -0.5 0 $color-black-30;

    @if ($color != none) {
      background: transparentize($color, 0.16);
    }

    @content;
  }
}

@mixin fill-up-animation($length: 1000px, $duration: 1s) {
  animation: fillup $duration forwards $swift-out;
  stroke-dasharray: $length;
  stroke-dashoffset: $length;
}
