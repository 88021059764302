// System ----------------------------------------------------------------------
$node-env: 'prodiction' !default;
$platform: 'mobile' !default;
$environment: 'browser' !default;
$is-mobile: $platform == 'mobile';
$is-desktop: $platform == 'desktop';
$is-browser: $environment == 'browser';
$is-cordova: $environment == 'cordova';
$ios-statusbar-height: 20px;

// Breakpoints -----------------------------------------------------------------
$mobile: 320px;
$mobile-landscape: 480px;
$tablet: 640px;
$tablet-wide: 768px;
$desktop: 960px;
$widescreen: 1280px;

$large-container: 996px;

// Fonts -----------------------------------------------------------------------
$font-title: 'Lato', sans-serif;
$font-main: 'Lato', Arial, sans-serif;
$font-secondary: 'Lato', sans-serif;
$font-currency: 'Encode Sans', sans-serif;
$font-tag: 'Lato';
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-extrabold: 800;

// TODO: WIP - Migrate new color variables: ------------
$color-pink: #e00280;
$color-primary-variant: #0a72bf;
$color-primary-disabled: #c2e3fa;
$color-primary-selected: #edf7fe;
$color-primary-dragged: #d0e9fb;
$color-primary-focus: #e2f1fc;
$color-primary-hover: #f0f5f8;
$color-error: #fc4a37;

$color-primary-shade-1: #031828;
$color-primary-shade-2: #063050;
$color-primary-shade-3: #094978;
$color-primary-shade-4: #0c61a0;
$color-primary-shade-5: #0f79c8;

$color-primary-light-1: #3ca3f0;
$color-primary-light-2: #63b6f3;
$color-primary-light-3: #8ac8f6;
$color-primary-light-4: #9db7f9;
$color-primary-light-5: #d8edfc;

$color-secondary-low: #dfbdff;
$color-secondary-pure: #7b48b6;
$color-secondary-hight: #3d245b;
$color-secondary-variant: #623a92;
$color-secondary-shade-1: #311d49;
$color-secondary-shade-2: #3d245b;
$color-secondary-shade-3: #56327f;
$color-secondary-shade-4: #623a92;
$color-secondary-shade-5: #6f41a4;

$color-secondary-light-1: #9166c3;
$color-secondary-light-2: #a785cf;
$color-secondary-light-3: #bda3db;
$color-secondary-light-4: #d3c2e7;
$color-secondary-light-5: #e9e0f3;

$color-shade-0: #000;
$color-shade-1: #212121;
$color-shade-2: #666;
$color-shade-3: #a9a9a9;
$color-shade-4: #f6f6f6;
$color-shade-outline: rgba(0, 0, 0, 0.12);
$action-label-secondary-color: var(--color-brand-04);
// --color-Neutral-1: var(color-Neutral-1);
// var(--color-Neutral-2): var(color-Neutral-2);
// --color-Neutral-3: var(color-Neutral-3);
// --color-Neutral-4: var(color-Neutral-4);
// --color-Neutral-5: var(color-Neutral-5);
// $color-Neutral-6: var(color-Neutral-6);

// Feedback colors
$color-positive-low: #bdffdc;
$color-positive-pure: #00d966;
$color-positive-high: #00622e;

$color-warning-low: #fce0be;
$color-warning-pure: #f7931a;
$color-warning-high: #8e4e00;

$color-negative-low: #ffcac5;
$color-negative-pure: #fc4a37;
$color-negative-high: #8c0d00;

$color-informative-low: #b1daf9;
$color-informative-pure: #1591ed;
$color-informative-high: #095187;


$color-disabled: rgba(0, 0, 0, 0.15);

$skeletonColor1: #00000052;
$skeletonColor2: #e0e0e0eb;
$skeleton-animation-duration: 1.5s;

$on-action-label-primary-color: var(color-contrast-01);
$on-action-icon-primary-color: var(color-contrast-01);
$categories-background: linear-gradient(175deg, #4a7fa8 20%, #796295 100%);

// TODO: WIP Figma section "States"
// Move this part for a different file after full migration

// Shadows:
%shadows--under {
  box-shadow: inset 0px 3px 3px -3px rgba(0, 0, 0, 0.2);
}

%shadows--blended {
}

%shadows--touching {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

%shadows--close {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.07), 0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.1);
}

%shadows--far {
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 24px rgba(0, 0, 0, 0.1);
}

%shadows--dragged {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}
%shadows--level2 {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
}

// Typography:

%typography-label {
  font-family: $font-main;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.8;

  @media screen and (min-width: $tablet) {
    line-height: 1.5;
  }
}

%typography-label-small-data {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.75;
}

%typography-label-big-data {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.1;
}

%typography-label-caps {
  @extend %typography-label;
  text-transform: uppercase;
}

%typography-label-input {
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5px;
  letter-spacing: -0.25px;

  @media screen and (min-width: $tablet) {
    font-size: 16px;
    line-height: 1.33;
  }
}

%typography-thumb-outcome-name {
  font-family: $font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}

%typography-thumb-number {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.85;
}

%typography-thumb-caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
}

%typography-caption-condensed {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

%typography-headline-small-title {
  font-family: $font-main;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25px;
  letter-spacing: -0.5px;

  @media screen and (min-width: $tablet) {
    letter-spacing: normal;
  }
}

%typography-text-small-title {
  font-family: $font-main;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

%typography-headline {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.5;
  letter-spacing: 0.18px;

  @media screen and (min-width: $tablet) {
    font-family: $font-main;
    font-weight: 300;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: normal;
  }
}

%typography-headline-big {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.23;
  display: flex;
  letter-spacing: 0.18px;

  @media screen and (min-width: $tablet) {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: 1.2px;
  }
}

%typography-question-headline {
  font-family: $font-main;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.5px;
}

%typography-question-headline-big {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  @media screen and (min-width: $tablet) {
    font-size: 20px;
    line-height: 1.2;

    letter-spacing: -0.5px;
  }
}

%form-text-field {
  appearance: none;
  background: transparent;
  border-radius: 8px;
  border: 0.1em solid var(--color-Neutral-3);
  color: var(--color-Neutral-6);

  &:placeholder-shown {
    border-bottom-color: $color-shade-3;
  }
  height: 54px;
  margin: 0;
  outline: 0;
  padding: 0.8em 0 0.8em;
  width: 100%;
  min-width: 0.5em;
  transition: 150ms ease-out border-color;
  padding: 12px 16px;
  gap: 4px;

  &:-webkit-autofill {
    box-shadow: inset 0 14px 28px white;
  }

  &:focus {
    border-bottom-color: var(--color-primary-low);
  }

  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.5);
    font-weight: 400;
  }
}

%heading-sm {
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133%;
}
%heading-xs {
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}
%heading-xxl {
  font-family: Lato;
  font-size: 64px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
}
%heading-xl {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
}
%heading-xxs {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
}
%heading-md {
  font-family: Lato;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
}
%label-md {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}
%label-rg {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}
%label-sm {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
%label-lg {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
}

%paragraph-sm {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}
%description {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

%hashtag {
  background: $color-shade-4;
  border-radius: 2px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-size: 12px;
  font-family: $font-main;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 16px;
  margin: 8px 8px 8px 0;
  padding: 0.5em 1.25em;
  text-transform: uppercase;
}
@keyframes segmentAnimation {
  0% {
    background-position: 0% 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.skeleton {
  background-color: var(--color-Neutral-3);
  // The shine that's going to move across the skeleton:
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-size: 40px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

// </TODO>
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
// Color Pallete ---------------------------------------------------------------
$color-black: #000;
$color-black-transparent: transparentize($color-black, 1);
$color-black-10: transparentize($color-black, 0.9);
$color-black-20: transparentize($color-black, 0.8);
$color-black-25: transparentize($color-black, 0.75);
$color-black-30: transparentize($color-black, 0.7);
$color-black-40: transparentize($color-black, 0.6);
$color-black-50: transparentize($color-black, 0.5);
$color-black-60: transparentize($color-black, 0.4);
$color-black-75: transparentize($color-black, 0.25);
$color-black-90: transparentize($color-black, 0.1);
$color-white-ios: #f8f8f8;
$color-white: #ffffff;
$color-white-transparent: transparentize($color-white, 1);
$color-white-10: transparentize($color-white, 0.9);
$color-white-25: transparentize($color-white, 0.75);
$color-white-30: transparentize($color-white, 0.7);
$color-white-40: transparentize($color-white, 0.6);
$color-white-50: transparentize($color-white, 0.5);
$color-white-60: transparentize($color-white, 0.4);
$color-white-75: transparentize($color-white, 0.25);
$color-white-90: transparentize($color-white, 0.1);
$color-grey0: #f6f6f6;
$color-grey1: #ededed;
$color-grey1-20: transparentize($color-grey1, 0.8);
$color-grey1-30: transparentize($color-grey1, 0.7);
$color-grey1-50: transparentize($color-grey1, 0.5);
$color-grey1-80: transparentize($color-grey1, 0.2);
$color-grey2: #c2ced1;
$color-grey2-80: transparentize($color-grey2, 0.2);
$color-grey3: #a6b6bd;
$color-grey3-20: transparentize($color-grey3, 0.8);
$color-grey3-50: transparentize($color-grey3, 0.5);
$color-grey4: #4c626d;
$color-grey4-10: transparentize($color-grey4, 0.9);
$color-grey4-25: transparentize($color-grey4, 0.75);
$color-grey4-50: transparentize($color-grey4, 0.5);
$color-grey4-70: transparentize($color-grey4, 0.3);
$color-grey5: #0f2834;
$color-grey5-10: transparentize($color-grey5, 0.9);
$color-grey5-60: transparentize($color-grey5, 0.4);
$color-grey5-90: transparentize($color-grey5, 0.1);
$color-pink-text: $color-pink;
$color-pink-text-60: transparentize($color-pink-text, 0.4);
$color-magenta: #b12197;
$color-purple: #8241ad;
$color-purple-blue: #7b48b6;
$color-vivid-violet: #a03597;
$color-lilac: #5361c4;
$color-blue: #0027cc;
$color-curious-blue: #0a72bf;
$color-blue-text: #0027cc;
$color-blue-facebook: #3a5998;
$color-light-blue: #c2e3fa;
$color-red: $color-pink;
$color-red-dark: #8f0e3d;
$color-green-whatsapp: #25d366;

// Shadows ---------------------------------------------------------------------
$drop-shadow: 0 0 20px $color-black-25;
$card-shadow: 0 1px 2px $color-black-25;

// Gradients -------------------------------------------------------------------
$gradient-angle: 45deg;
$gradient-angle-outcome: 144deg;
$gradient-full-color-start: $color-pink;
$gradient-full-color-end: $color-blue;
$gradient-full-color-mix: $color-purple;

$gradient-full: linear-gradient($gradient-angle, $gradient-full-color-start, $gradient-full-color-end);

$gradient-bg: linear-gradient(75deg, $color-magenta 25%, $color-lilac);
$gradient-bg-reverse: linear-gradient(75deg, $color-lilac 25%, $color-magenta);
$gradient-fade-bg: linear-gradient(75deg, rgba($color-magenta, 0.82) 25%, rgba($color-lilac, 0.82));

$gradient-fade-horizontal: linear-gradient(
  90deg,
  transparentize($color-magenta, 0.1),
  transparentize($color-lilac, 0.1)
);

$gradient-cold: linear-gradient($gradient-angle-outcome, $color-blue, $color-purple);
$gradient-colder: linear-gradient($gradient-angle-outcome, $color-blue, $color-purple-blue);

$gradient-hot: linear-gradient($gradient-angle-outcome, $color-magenta, $color-purple-blue);

$gradient-fade-cold: linear-gradient(
  $gradient-angle-outcome,
  transparentize(#0027cc, 0.2),
  transparentize($color-purple, 0.2)
);

$gradient-fade-hot: linear-gradient(
  $gradient-angle-outcome,
  transparentize($color-pink, 0.2),
  transparentize($color-purple, 0.2)
);

$gradient-black: linear-gradient(135deg, $color-grey4 0%, $color-grey5);
$gradient-grey: linear-gradient(135deg, $color-grey3, $color-grey4);

// Sizes -----------------------------------------------------------------------
$overscroll-size: 100px;
$text-size-label: 10px;
$text-size-label-big: 12px;
$text-size-button: 14px;
$list-item-margin: 25px;
$list-item-margin-vertical: 30px;
$navbar-mobile-height: 96px;
$navbar-desktop-height: 96px;

// Animations ------------------------------------------------------------------
$swift-out: cubic-bezier(0.55, 0, 0.1, 1);
$ease-in-out: cubic-bezier(0.75, 0, 0.25, 1);
$notification-easing: cubic-bezier(0.005, 0.75, 0.25, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

$time-fast: 100ms;
$time-normal: 250ms;
$time-slowy: 500ms;
$time-slow: 1000ms;
$time-very-slow: 1500ms;
$time-slowest: 3000ms;

$animation-fast: $time-fast $swift-out;
$animation-normal: $time-normal $swift-out;
$animation-slowy: $time-slowy $swift-out;
$animation-slow: $time-slow $swift-out;
$animation-very-slow: $time-very-slow $swift-out;
$animation-slowest: $time-slowest $swift-out;

// Element Definitions ---------------------------------------------------------
$toolbar-padding: 8px;
$navbar-height: 50px;
$navbar-attachment-height: 45px;
$navbar-icon-size: 22px;
$tabbar-height: 78px;
$tabbar-icon-size: 20px;
$button-height-normal: 32px;
$button-height-big: 44px;
$giant-button-height: 60px;
$list-separator-color: $color-grey0;
