@import 'common/styles/global';

.active-markets {
  padding-top: 64px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  @media screen and (max-width: 768px) {
    gap: 4px;
    align-items: flex-end;
    border-top: none;
    font-size: 16px;
  }
  &--left {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--color-Neutral-6);
    @media screen and (max-width: 768px) {
      justify-content: space-between;
      width: 100%;
    }
  }
  &__quantity {
    font-size: 14px;
    color: var(--color-Neutral-4);
    font-weight: 400;
    align-self: center;
  }

  &__mobileRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
    @media screen and (min-width: 768px) {
      display: contents;
    }
  }
  .market-filter {
    &.filter-dropdown-control {
      &--open,
      &--selected {
        .filter-dropdown-control__value {
          color: unset;
          box-shadow: unset;
        }
      }
    }
    .filter-dropdown-control__value {
      border: none;
      background-color: unset;
      gap: 4px;
      padding-right: unset;
      &:hover {
        color: unset;
      }
    }
    .filter-dropdown-control__placeholder {
      font-weight: 500;
    }
    .filter-dropdown-control__list {
      left: unset;
      right: -4px;
      top: 50px;
    }
    .filter-dropdown-control__option {
      font-weight: 500;
    }
  }
}
