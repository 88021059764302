@import 'declarations';

$grid-columns: 16;
$grid-gutter-width: 15px;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: $tablet,
  // Medium screen / tablet
  md: $tablet-wide,
  // Large screen / desktop
  lg: $desktop,
  // Extra large screen / wide desktop
  xl: $widescreen,
  // Extra large screen / wide desktop
);

$container-max-widths: (
  sm: 100%,
  md: 640px,
  lg: 760px,
  xl: 1200px,
);

@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/utilities/sizing';


.container-wide {
  @extend .container;
  max-width: calc(100% - 30px)
}

.container-sm-wide {
  margin: 0 auto;
}

.container-md-wide {
  margin: 0 auto;
}


.container-wide, .container-sm-wide {
  @media screen and (min-width: $tablet) {
    max-width: calc(100% - 80px);
  }
}

.container-md-wide {
  @media screen and (min-width: $tablet-wide) {
    max-width: calc(100% - 80px);
  }
}

.container-md-wide, .container-wide, .container-sm-wide {
  @media screen and (min-width: $widescreen) {
    max-width: 1200px;
  }
}
