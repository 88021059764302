@import 'common/styles/global';
@import 'common/styles/transitions';

.view-category-details {
  @extend %full-page-height;
  background-color: var(--color-surface-1);
  &__banner-container {
    @media screen and (min-width: 712px) {
      padding-top: 40px;
    }
  }

  &__subDetails {
    padding: 0 !important;
    padding-top: 30px !important;
    padding-bottom: 32px !important;
    &--underline {
      border-bottom: 1px solid #d9d9d9;
    }
    .breadcrumb__sep,
    .breadcrumb__item {
      font-family: $font-main;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 14px;
      color: var(--color-Neutral-4);
    }

    .swiper-slide {
      width: auto !important;
    }
    &--mainSub {
      font-family: $font-main;
      font-weight: 700;
      font-size: 40px;
      margin: 0;
      margin-top: 16px;
      line-height: 120%;
    }
    &--childrens {
      padding: 0;
      margin: 0;
      margin-top: 16px;
      display: flex;
      gap: 12px;
      &--swipe {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
      }
      &--swipeMobile {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        position: relative;
        justify-content: center;
      }
    }
    &--sub {
      background-color: var(--color-Neutral-1);
      border-radius: 8px;
      font-size: 14px;
      font-family: $font-main;
      font-weight: 500;
      letter-spacing: 1.25px;
      padding-left: 16px;
      padding-right: 16px;
      height:40px;
      display:flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      color: var(--color-Neutral-6); 
      white-space: nowrap;
      &:hover {
        background: var(--color-primary-low);
        border-color: var(--color-primary-low);
        transition: 200ms;
        color: var(--color-primary-pure);
        font-weight: 500;
      }
    }
  }
  // &__banner-container {
  //   padding-top: 26px;
  // }

  &__share-panel {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  @media screen and (min-width: $tablet-wide) {
    &__grid {
      padding: 30px 0;
    }

    &__share-panel {
      padding: 38px 0;
    }

    // &__banner-container {
    //   padding-top: 64px;
    // }
  }

  @media screen and (min-width: $desktop) {
    &__grid {
      max-width: 1132px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }

    &__column {
      margin-right: 0;
      max-width: calc(50% - 10px);
    }
  }
}

.banner-navbar + .view-container {
  .view-category-details .infinite-grid {
    padding-top: 350px;
  }
}
