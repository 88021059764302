@import 'common/styles/global';

.action-sheet {
  background-color: var(--color-Neutral-1);
  color: var(--color-Neutral-6);
  border-radius: 10px;
  min-height: 100%;
  overflow: hidden;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (max-width: $desktop - 1) {
    height: fit-content;
    margin-top: auto;
    min-height: auto;
  }
  &__header {
    font-weight: 700;
    padding: 26px 32px 24px;
    position: relative;
    @media screen and (max-width: $desktop - 1) {
      padding: 20px 14px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    padding: 24px 32px 24px;
    @media screen and (max-width: $desktop - 1) {
      padding: 20px 14px;
    }
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 18px;
    font-size: 12px;
    color: var(--color-Neutral-6);
    padding: 14px;
    border-radius: 500px;
    display: flex;
    &:hover {
      background-color: var(--color-Neutral-2);
    }
  }

  &__container h2 {
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    max-width: 90%;
    margin: 0.5em 0 1em;
    font-family: $font-secondary;
  }

  &__container p {
    color: var(--color-Neutral-5); 
  }

  @media screen and (min-width: $tablet) {
    border-radius: 10px;
    height: auto;
    left: 50%;
    margin: 0 auto;
    min-height: 0;

    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 690px;
    &--not-centered {
      transform: translateX(-50%);
      top: 0px;
      margin-bottom: 64px;
    }
  }
}
