@import 'declarations';
@import 'helpers';
@import 'placeholders';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  height: 100dvh;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-Neutral-1) inset !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--color-Neutral-6) !important;
}
.ql-snow .ql-stroke {
  stroke: var(--color-Neutral-5);
}
:root {
  @media screen and (max-width: 768px) {
    --toastify-toast-max-height: 900px;
  }
}

body {
  font-family: 'Lato', sans-serif;
}
.link {
  font-family: Lato;

  color: var(--color-primary-pure);
  &:hover {
    color: var(--color-primary-high);
  }
  &__disabled {
    pointer-events: none;
    color: var(--color-Neutral-3);
  }
}
.container-wide {
  padding: 0;
}
.row {
  margin-left: unset;
  margin-right: unset;
}
.react-modal-sheet-container {
  background-color: var(--color-Neutral-1) !important;
}
.text-gradient {
  background: -webkit-linear-gradient(90deg,#FF5129 16.17%, #F7333F 23.13%, #EF1755 30.97%, #EA0663 37.94%, #E90068 44.03%, #CE0179 51.87%, #8B06A4 68.41%, #200DEA 92.8%, #0010FF 99.76%);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #e5414100;
}

/* Hide scrollbar for Firefox */
.scrollbar-none {
  scrollbar-width: none;
}
