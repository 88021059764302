@import 'common/styles/global';

.text-field-el {
  &__label {
    @extend %typography-label;
    font-size: 14px;
    font-weight: 600;
    transition: 200ms;

    &--error {
      color: var(--color-negative-4);
    }
  }

  &__icons-container {
    position: absolute;
    right: 16px;
    top: 36%;
    display: flex;
    align-items: center;
    gap: 8px;
    // transition: 200ms;

    svg {
      max-height: 22px;
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 100%;
    background-color: transparent;
  }
  &__input-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  &__container {
    position: relative;
    height: 56px;
    @extend %form-text-field;
    padding: 0;
    transition: background-color 200ms;
    transition: border-color 200ms;
    display: flex;
    flex-flow: column;
    padding: 16px;

    &:hover {
      // background-color: var(--color-Neutral-3);
      border: 1px solid var(--color-Neutral-6);
      cursor: text;
    }

    &--error {
      border-color: var(--color-negative-4) !important;
      &,
      label,
      input {
        color: var(--color-negative-4);
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active { 
        -webkit-text-fill-color: var(--color-negative-4) !important;
      }
    }

    &:active,
    &:focus,
    &:focus-within {
      background-color: unset;
      border-color: var(--color-brand-04);
      .text-field-el__suffix {
        display: unset;
      }
    }
    &--noLabel {
      padding: 0 16px;
      .text-field-el__input {
        height: 100%;
      }
    }
    &--withvalue {
      // .text-field-el__prefix {
      //   color: unset;
      // }
      .text-field-el__suffix {
        display: unset;
      }
      .text-field-el__input {
        position: relative;
      }
    }
    &--read-only {
      background-color: var(--color-Neutral-3);
      color: var(--color-Neutral-4);
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }
    &--active {
      .text-field-el__input {
        position: relative;
      }
    }
    &--disabled {
      background-color: var(--color-Neutral-3);
      color: var(--color-Neutral-4);
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }
    &--text-area {
      .text-field-el__input {
        position: relative;
      }
      height: unset;
      label {
        top: 24px;
      }
      textarea {
        margin-top: 16px;
        max-width: 100%;
        min-height: 100px;
        min-width: 100%;
      }
    }
  }

  &__input-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-Neutral-6);
  }

  &__input {
    border: none;
    padding: 0;
    outline: none;
    background-color: transparent !important;
    height: 100%;
    position: absolute;
    font-size: 16px;
    color: var(--color-Neutral-6);
    width: 100%;
    &--sizer {
      pointer-events: none;
      position: absolute;
      opacity: 0;
    }
  }

  &__input[type='password'] {
    letter-spacing: 0.35em;
  }

  &__currency-field-container {
    position: relative;
    overflow: hidden;
  }

  &__suffix {
    position: absolute;
    font-size: 16px;
    color: var(--color-Neutral-6);
    font-family: $font-main;
    font-weight: 400;
    display: none;
    padding-left: 16px;
    pointer-events: none;
  }
  &__prefix {
    color: var(--color-Neutral-4);
  }

  &__helper,
  &__error {
    text-align: left;
    font-size: 14px;
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-negative-4);
  }

  &__icon {
    color: var(--color-primary-pure);
    position: absolute;
    right: 0;
    top: 0.65em;
    font-size: 20px;
  }

  @media screen and (min-width: $tablet-wide) {
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
