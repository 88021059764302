:root {
  --color-Neutral-1: #ffffff;
  --color-Neutral-2: #edefed;
  --color-Neutral-3: #c2ced1;
  --color-Neutral-4: #4c626d;
  --color-Neutral-5: #0f2834;
  --color-Neutral-6: #050f14;
  --color-modal-background: #ffffff;
  --color-surface-1: #e8ecff;

  --color-primary-low: #9db7f9;
  --color-primary-pure: #0027cc;
  --color-primary-high: #061989;
  --action-background-primary-color: #0027cc;

  --color-brand-01: #e8ecff;
  --color-brand-02: #9db7f9;
  --color-brand-03: #1836f5;
  --color-brand-04: #0027cc;
  --color-brand-05: #061989;
  --color-brand-06: #12113a;

  --color-positive-1: 229, 245, 235;
  --color-positive-2: 178, 224, 196;
  --color-positive-3: 51, 172, 97;
  --color-positive-4: 0, 151, 58;
  --color-positive-5: 0, 91, 35;
  --color-positive-6: 0, 60, 23;

  --color-warning-1: #fef4e8;
  --color-warning-2: #fddfba;
  --color-warning-3: #fabe76;
  --color-warning-4: #f7931a;
  --color-warning-5: #945810;
  --color-warning-6: #633b0a;

  --color-negative-1: 251, 233, 231;
  --color-negative-2: 244, 188, 182;
  --color-negative-3: 234, 121, 109;
  --color-negative-4: 220, 31, 11;
  --color-negative-5: 132, 19, 7;
  --color-negative-6: 88, 12, 4;

  --color-informative-1: #e8f4fd;
  --color-informative-2: #b9defa;
  --color-informative-3: #73bdf4;
  --color-informative-4: #1591ed;
  --color-informative-5: #0d578e;
  --color-informative-6: #083a5f;

  --color-informative2-1: #ffeef6;
  --color-informative2-2: #ffcce5;
  --color-informative2-3: #ff98cb;
  --color-informative2-4: #ff54a8;
  --color-informative2-5: #993265;
  --color-informative2-6: #662243;

  --color-auxiliaries-3: 157, 41, 89;

  --color-contrast-01: #ffffff;
  --color-contrast-02: #050f14;
  --color-page-error: #c10000;
  --color-chart-tooltip-background: rgba(255, 255, 255);

  --toastify-color-light: #fff;
  --toastify-color-dark: #050f14;
 
}
html[data-theme='dark'] {
  --color-Neutral-1: #0f2834;
  --color-Neutral-2: #050f14;
  --color-Neutral-3: #4c626d;
  --color-Neutral-4: #c2ced1;
  --color-Neutral-5: #edefed;
  --color-Neutral-6: #ffffff;
  --color-modal-background: #050f14;
  --color-surface-1: #050f14;

  --color-primary-low: #061989;
  --color-primary-pure: #9db7f9;
  --color-primary-high: #0027cc;
  --action-background-primary-color: #9db7f9;

  --color-brand-01: #061989;
  --color-brand-02: #9db7f9;
  --color-brand-03: #9db7f9;
  --color-brand-04: #9db7f9;
  --color-brand-05: #e8ecff;
  --color-brand-06: #e8ecff;

  --color-contrast-01: #050f14;
  --color-contrast-02: #ffffff;
  --color-page-error: #ff9a9a;

  --color-chart-tooltip-background: rgb(5 15 20);
}
