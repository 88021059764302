@import 'common/styles/global';

.email-not-confirmed {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: var(--color-Neutral-6);
    i {
      background-color: var(--color-primary-low);
      padding: 16px;
      border-radius: 100%;
      display: flex;
    }
    &--title {
      @extend %heading-xs;
    }
    &--description {
      @extend %paragraph-sm;
      text-align: center;
      max-width: 500px;
      .link {
        font-size: 16px !important;
      }
      b {
        font-weight: 600;
      }
    }
    button {
      margin-top: 16px;
    }
    &--buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
    &--button{
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
  }
}
