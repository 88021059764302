@import 'common/styles/global';

.tab-scroll-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__tabs {
    display: flex;
    border-bottom: 2px solid var(--color-Neutral-3);
    padding: 24px 32px 8px 32px;
    position: relative;

    flex-shrink: 0;
    @media screen and (max-width: $desktop) {
      padding: 8px;
    }
  }

  &__content {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
  }

  &--clean &__tabs {
    box-shadow: none;
    height: 43px;

    .tab {
      border-bottom-width: 3px;
      border-right: 0;
      flex: 0;
      font-family: $font-main;
      font-size: 20px;
      font-weight: 600;
      margin-right: 30px;
      text-transform: none;
    }

    .tab:not(.tab--active) {
      color: rgba($color-grey5, 0.2);
    }

    .tab:last-child {
      margin-right: 0;
    }
  }
}

.tab-scroll-view__tabs .tab {
  display: flex;
  color: var(--color-Neutral-4);
  align-items: center;
  justify-content: space-around;
  height: 32px;
  transition: transition(250ms $notification-easing, border-bottom-width, color);
  cursor: pointer;
  border-radius: 8px;
  padding: 0 16px;
  &:hover {
    background-color: var(--color-primary-pure);
    color: var(--color-Neutral-1);
  }
  &:last-child {
    border-right: 0;
  }

  &--active {
    border-bottom-color: var(--color-primary-pure);
    color: var(--color-primary-pure);
    font-size: 16px;
    font-weight: 600;
    position: relative;
  }
  &--underline {
    background-color: var(--color-primary-pure);
    height: 2px;
    position: absolute;
    bottom: -10px;
    width: 100%;
    display: block;
  }
}

@media (min-width: $desktop) {
  .tab-scroll-view {
    &__tabs {
      width: 100%;
    }

    &__content {
      width: 100%;
      // min-height: calc(100vh - 130px);
      overflow: auto;
    }
  }
}
