@import 'global';
// This file is imported by _global and all variables here are defined prior importing this file.

.button {
  $button-radius: 8px;
  $button-padding: $button-radius;

  appearance: none;
  background-color: var(--color-Neutral-1);
  border: 1px solid $color-grey2;
  border-radius: $button-radius;
  color: $color-grey4;
  cursor: pointer;
  display: inline-block;
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  height: $button-height-normal;
  letter-spacing: -0.01px;
  line-height: $button-height-normal;
  outline: none;
  padding: 0 $button-padding;
  text-align: center;
  text-transform: uppercase;
  transition: transition($animation-normal, opacity, background, box-shadow);

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &:active {
    background-color: $color-grey1;
    box-shadow: 0 0 4px $color-black-25;
    transition-duration: $animation-fast;
  }

  &--icon {
    $icon-size: 24px;

    padding-left: $button-padding + $icon-size;
    background-position: calc($button-padding / 2) center;
    background-repeat: no-repeat;
  }

  &--small {
    // New Style
    display: block;
    width: 72px;
    height: 25px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: $font-weight-semibold;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  &--big {
    $button-padding: $button-radius;

    width: 100%;
    height: $button-height-big;
    padding: 0 $button-padding;
    border-radius: $button-radius;
    line-height: $button-height-big;

    &.button--icon {
      $icon-size: 30px;

      padding-left: $button-padding + $icon-size;
      background-position: calc($button-padding / 2) center;
      background-size: $icon-size;
    }
  }

  &--primary {
    border: 0;
    background-color: $color-blue;
    color: var(--color-Neutral-1);

    &:active {
      background-color: darken($color-blue, 20%);
    }
  }

  &--outline {
    border: 1px solid $color-blue;
    background-color: var(--color-Neutral-1);
    color: $color-blue;

    &:active {
      background-color: darken($color-blue, 20%);
    }
  }

  &--dark {
    border: 0;
    background-color: $color-white-25;
    color: var(--color-Neutral-1);

    &:active {
      background-color: darken($color-white-25, 20%);
    }
  }

  &--transparent {
    border: 1px solid var(--color-Neutral-1);
    background-color: $color-white-transparent;
    color:var(--color-Neutral-1) ;

    &:active {
      background-color: $color-white-10;
    }
  }

  &--giant {
    height: $giant-button-height;
    line-height: $giant-button-height;
  }
}

@mixin button($size, $type) {
  $button-radius: 8px;
  $button-padding: $button-radius;

  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  @if $size == small {
    font-size: 14px;
  }
}
