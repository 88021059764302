.suspense {
  &__page {
    height: calc(100vh - 64px);
    background-color: var(--color-surface-1);
  }
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
