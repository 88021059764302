@import 'common/styles/global';

.market-page {
  &__loading {
    display: flex;
    align-items: center;
    padding: 2.5em 0;
    min-height: 520px;
  }

  &__wrapper {
    min-height: 520px; 

    @media screen and (min-width: 1200px) {
      .infinite-grid__column {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  &__column {
    .question-card__section:not(.question-card__section--wide) {
      padding: 24px 18px 19px 24px;
    }
    .question-card__bet-status {
      padding-top: 0;
    }
  }
  @media screen and (min-width: 1200px) {
    &__column {
      margin-right: 0;
      max-width: calc(50% - 10px);
    }
  }
  &__filters {
    .filter-dropdown-control {
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  &__card-skeleton {
    background-color: var(--color-Neutral-1);
    border-radius: 8px;
    padding: 24px 18px 19px 24px;
    margin-bottom: 20px;
    min-height: 300px;
    user-select: auto;
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;
    // position: relative;
    // border: 1px solid var(--color-Neutral-3);
    // width: 100%;
    // background: linear-gradient(to left, $skeletonColor2, $skeletonColor1, $skeletonColor2);
    // background-size: 200% 100%;
    // animation: segmentAnimation $skeleton-animation-duration infinite linear;
    &--header {
      min-height: 42px;
      border-radius: 8px;
      max-width: 80%;
    }
    &--categories {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;

      &--category {
        min-height: 20px;
        border-radius: 8px;
        min-width: 72px;
      }
    }
    &--outcomes {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;
      flex-direction: column;
      margin: auto 0;
    }
    &--outcome {
      min-height: 44px;
      border-radius: 8px;
      width: 100%;
    }
    &--footer {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      &--details {
        min-height: 20px;
        border-radius: 8px;
        min-width: 30%;
      }
    }
  }
}
