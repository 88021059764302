@import 'declarations';

@keyframes hide-up-animation {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }

  to {
    opacity: 0;
    transform: translate(0, -100%);
  }
}

@keyframes show-down-animation {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fillup {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translate(0, 100px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.transition {
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    width: 100%;
    min-height: 100%;
    //will-change: transform; // Causes bug with fixed positioned children
  }

  &--slide {
    &-left {
      &-enter {
        transform: translateX(100%);
      }

      &-enter-active {
        transform: translateX(0);
        transition: transform $animation-normal;
      }

      &-leave {
        transform: translateX(0);
      }

      &-leave-active {
        transform: translateX(-100%);
        transition: transform $animation-normal;
      }
    }

    &-right {
      &-enter {
        transform: translateX(-100%);
      }

      &-enter-active {
        transform: translateX(0);
        transition: transform $animation-normal;
      }

      &-leave {
        transform: translateX(0);
      }

      &-leave-active {
        transform: translateX(100%);
        transition: transform $animation-normal;
      }
    }

    &-up {
      &-appear {
        transform: translateY(100%);
      }

      &-appear-active {
        transform: translateY(0);
        transition: transform $animation-normal;
      }

      &-enter {
        transform: translateY(100%);
      }

      &-enter-active {
        transform: translateY(0);
        transition: transform $animation-normal;
      }

      &-leave {
        transform: translateY(0);
      }

      &-leave-active {
        transform: translateY(100%);
        transition: transform $animation-normal;
      }
    }

    &-down {
      &-appear {
        transform: translateY(-100%);
      }

      &-appear-active {
        transform: translateY(0);
        transition: transform $animation-normal;
      }

      &-enter {
        transform: translateY(-100%);
      }

      &-enter-active {
        transform: translateY(0);
        transition: transform $animation-normal;
      }

      &-leave {
        transform: translateY(0);
      }

      &-leave-active {
        transform: translateY(-100%);
        transition: transform $animation-normal;
      }
    }
  }
}
