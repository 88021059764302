@import 'common/styles/global';

.tooltip {
  color: var(--color-Neutral-1);
  position: absolute;
  z-index: 8;
  &__arrow {
    background-color: var(--color-brand-02) ;
    z-index: 8;
    &--light {
      background-color: var(--color-Neutral-1);
    }
    height: 18px;
    left: 50px;
    position: absolute;
    top: 0;
    transform: rotateZ(45deg);
    width: 18px;
  }

  &__content {
    font-family: $font-main;
    border-radius: 8px;
    background-color:  var(--color-brand-02) ;
    color: var(--color-Neutral-1);
    &--light {
      background: var(--color-Neutral-1);
      color: #6b6a6a;
      padding: 16px;
      box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 15%);
      font-size: 14px;
      font-family: 'Lato';
      font-weight: 400;
    }
    position: relative;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.35;
    margin-top: 9px;
    padding: 24px 22px 12px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
  }

  &__button {
    margin-left: 8px;
  }
}
