@import 'common/styles/global';

.breadcrumb {
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.25;
  list-style: none;
  margin: 0 -.5em;
  padding: 0;
  text-transform: uppercase;
  &__link:hover {
    color: unset;
    text-decoration: none;
  }

  &__item {
    display: inline-block;
    margin: 0 0.5em;
    color: $color-shade-2;
    &:hover {
      a,&+span{

        font-weight: 600;
        color: var(--color-primary-high);
      }
    }
    &:last-child {
      color: rgba(255, 255, 255, 1);
    }
  }

  &__sep {
    color: $color-shade-2;
    margin: 0 4px;
    line-height: 1;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    display: inline-block;
    font-weight: inherit;
  }
}