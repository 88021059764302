@import '../../common/styles/global';

.category-list { 
  @include hide-scrollbar;
  margin-left: -8px;
  align-items: center;
  color: $color-grey2;
  display: flex;
  // overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  gap: 1px;
  // flex-wrap: wrap;
  &--icon {
    display: flex;
    margin-right: 4px;
    color: var(--color-Neutral-5);
  }
  &__item {
    color: var(--color-Neutral-4);
    cursor: pointer;
    pointer-events: auto;
    padding: 8px;
    border-radius: 8px;
    &:hover {
      background: var(--color-Neutral-3);
      color: var(--color-Neutral-6);
      
    }
    &:last-of-type {
      color: var(--color-Neutral-5);
      font-weight: 700;
    }
  }

  &__separator {
    @extend %tag;
    color: #a9a9a9;
    // margin: 0 8px;
    line-height: 1;

    &:last-child {
      display: none;
    }
  }

  &__currency {
    color: var(--color-primary-pure);
    font-size: 1.25em;
    font-weight: 700;
    margin-right: 0.25em;
    line-height: 1;

    &--OOM {
      text-transform: lowercase;
    }

    &--REAL_MONEY_PLACEHOLDER {
      svg {
        width: 20px;
      }
    }
  }
}
