@import 'common/styles/global';

.Toastify {
  --toastify-toast-top: 112px !important;
  &__close-button--light {
    color: var(--color-Neutral-5);
    opacity: 1;
  }
  &__toast {
    font-family: Lato;
    position: relative;
    padding: 24px 16px;
    border-radius: 16px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
    color: var(--color-Neutral-6);
    width: 100%;
    justify-content: space-between;
  }
  &__toast-body {
    padding: 0;
  }
  &__toast-container {
    @media screen and (min-width: $desktop - 1) {
      min-width: 400px;
      padding: 0px;
      display: inline-table;
      
    }
    @media screen and (max-width: $desktop){
      width: 100% !important;
    }
    padding: 16px;
   
  }
}
