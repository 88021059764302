:root {
  --chat--color-primary: var(--color-brand-03);
  --chat--color-primary-shade-50: var(--color-brand-04);
  --chat--color-primary-shade-100: var(--color-brand-05);
  --chat--color-secondary: var(--color-positive-4);
  --chat--color-secondary-shade-50: var(--color-positive-5);
  --chat--color-white: var(--color-Neutral-1);
  --chat--color-light: var(--color-Neutral-1);
  --chat--color-light-shade-50: var(--color-Neutral-3);
  --chat--color-light-shade-100: var(--color-Neutral-4);
  --chat--color-medium: var(--color-Neutral-3);
  --chat--color-dark: var(--color-Neutral-6);
  --chat--color-disabled: var(--color-Neutral-4);
  --chat--color-typing: var(--color-Neutral-5);

  --chat--spacing: 12px;
  --chat--border-radius: 16px;
  --chat--transition-duration: 0.15s;

  --chat--window--border: 1px solid var(--color-Neutral-3);
  --chat--window--width: 400px;
  --chat--window--height: 600px;

  --chat--header-height: auto;
  --chat--header--padding: var(--chat--spacing);
  --chat--header--background: var(--chat--color-white);
  --chat--header--color: var(--chat--color-dark);
  --chat--header--border-top: none;
  --chat--header--border-bottom: none;
  --chat--header--border-bottom: none;
  --chat--header--border-bottom: none;
  --chat--heading--font-size: 24px;
  --chat--header--color: var(--chat--color-dark);
  --chat--subtitle--font-size: 12px;
  --chat--subtitle--line-height: 1.8;

  --chat--textarea--height: 50px;
  --chat--input--background: var(--color-Neutral-1);
  --chat--input--text-color: 1px solid var(--color-Neutral-3);
  --chat--footer--background: var(--color-Neutral-1);
  --chat--footer--color: var(--color-Neutral-6);

  --chat--input--send--button--background: none;

  --chat--message--font-size: 1rem;
  --chat--message--padding: var(--chat--spacing);
  --chat--message--border-radius: var(--chat--border-radius);
  --chat--message-line-height: 1.8;
  --chat--message--bot--background: var(--color-Neutral-2);
  --chat--message--bot--color: var(--color-Neutral-6);
  --chat--message--bot--border: none;
  --chat--message--user--background: var(--color-brand-03);
  --chat--message--user--color: var(--color-Neutral-1);
  --chat--message--user--border: none;
  --chat--message--pre--background: var(--color-Neutral-2);

  --chat--toggle--background: var(--color-brand-03);
  --chat--toggle--hover--background: var(--color-brand-04);
  --chat--toggle--active--background: var(--color-brand-05);
  --chat--toggle--color: var(--color-Neutral-1);
  --chat--toggle--size: 64px;
}
