@import 'common/styles/global';

.view-signup {
  .action-sheet__container {
    padding: 0;
  }
  .action-sheet__content {
    padding: 24px 32px 24px;
  }
  @media screen and (min-width: 640px) {
    .action-sheet {
      width: 560px;
    }
  }
  &__small {
    @extend %heading-xxs;
    text-align: center;
    margin-top: 24px;
    &--terms a {
      text-decoration: underline;
    }

    &-link {
      color: var(--color-primary-pure);
    }
  }

  &__helper-text {
    color: var(--color-Neutral-6);
    font-size: 14px;
    &::before {
      content: '';
    }
  }

  &__header {
    font-weight: 300;
    line-height: 1.25;
    strong {
      display: block;
    }
  }

  &__username_warning {
    font-size: 14px;
    color: var(--color-Neutral-6);
  }

  &__username-warning-icon {
    display: flex;
    margin-right: 8px;
    width: 30px;
    color: $color-shade-3;
  }

  &__form {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text-field-el__content {
      gap: 1px;
    }
  }
  &__disclamer {
    @extend %description;
    color: var(--color-Neutral-4);
    a {
      text-decoration: underline;
    }
  }
  &__picture-badge {
    align-items: center;
    background-color: var(--color-primary-pure);
    border-radius: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    right: -1px;
    width: 16px;
    height: 16px;
    font-size: 22px;
    color: var(--color-Neutral-1);

    &--disabled {
      background-color: $color-grey1;
    }
  }

  &__picture-placehoder {
    position: absolute;
    left: 20px;
    top: 16px;
  }
  &__picture-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      340deg,
      #ff5129 16.17%,
      #f7333f 23.13%,
      #ef1755 30.97%,
      #ea0663 37.94%,
      #e90068 44.03%,
      #ce0179 51.87%,
      #8b06a4 68.41%,
      #200dea 92.8%,
      #0010ff 99.76%
    );
    > input {
      z-index: 100;
    }

    .profile-form__picture--huge > & {
      width: 160px;
      height: 160px;
    }
  }

  &__picture {
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0 auto;
  }

  &__info {
    color: var(--color-Neutral-4);
  }

  &__skip-link {
    color: var(--color-primary-pure);
    cursor: pointer;
  }

  &__loading {
    align-items: center;
    display: flex;
    margin-top: 1.5em;

    .loading {
      fill: var(--color-primary-low);
    }
  }

  &__paragraph {
    color: var(--color-Neutral-4);
    font-size: 16px;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  &__inbox-icon {
    align-items: center;
    color: #000;
    border-radius: 50%;
    background-color: var(--color-primary-low);
    display: flex;
    font-size: 60px;
    justify-content: center;
    line-height: 0;
    margin: 0 auto;
    margin-bottom: 24px;

    height: 56px;
    width: 56px;

    &--confirmation {
      background-color: var(--color-primary-pure);
      color: white;
    }
  }
}
